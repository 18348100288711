import { PrivateRoute } from "@/lib/PrivateRoute.tsx";
import FilloutMriFormPage from "@/pages/FilloutMriFormPage.tsx";

export const privateRoutes = [
	{
		path: "/*",
		element: (
			<PrivateRoute>
				<FilloutMriFormPage />
			</PrivateRoute>
		),
	},
];
