import { addDays, isBefore } from "date-fns";
import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: FormState = {
	createdAt: new Date(),
};

export const formStore = create<FormState & FormActions>()(
	persist(
		devtools((set) => ({
			...initialState,

			reset: () => set(initialState),
		})),
		{
			name: "form-store",
			onRehydrateStorage: (state) => {
				//if createdAt is older than 1 day, we reset the stores
				if (isBefore(state.createdAt, addDays(new Date(), -1))) {
					localStorage.clear();
				}
			},
		},
	),
);

type FormState = {
	createdAt: Date;
};

type FormActions = {
	reset: () => void;
};
