import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

const initialState: FormMetaDataState = {
	currentStep: 1,
	latestStep: 1,
	subscriptionId: null,
	invoiceUrl: null,
	paymentUrl: null,
	youSignUrl: null,
};

export const useFormMetaDataStore = create<
	FormMetaDataState & FromMetaDataActions
>()(
	persist(
		devtools((set, get) => ({
			...initialState,
			setCurrentStep: (step: number) => {
				set({ currentStep: step });
				if (step === 5 && get().subscriptionId !== null) {
					set({
						subscriptionId: null,
						invoiceUrl: null,
						youSignUrl: null,
						paymentUrl: null,
					});
				}
				const latestStep = get().latestStep;

				if (step === 5) {
					set({ latestStep: 5 });
				} else if (step >= latestStep) {
					set({ latestStep: step });
				}
			},
			setLatestStep: (step) => set({ latestStep: step, currentStep: step }),
			setSubscriptionId: (id) => set({ subscriptionId: id }),
			setInvoiceUrl: (url) => set({ invoiceUrl: url }),
			reset: () => set(initialState),
		})),
		{ name: "form-meta-data", getStorage: () => localStorage },
	),
);

type FormMetaDataState = {
	currentStep: number;
	latestStep: number;
	subscriptionId: string | null;
	invoiceUrl: string | null;
	youSignUrl: string | null;
	paymentUrl: string | null;
};

type FromMetaDataActions = {
	setCurrentStep: (step: number) => void;
	setLatestStep: (step: number) => void;
	setSubscriptionId: (id: string) => void;
	setInvoiceUrl: (url: string) => void;
	reset: () => void;
};
