import { FilloutFullScreenEmbed } from "@fillout/react";

import "@fillout/react/style.css";

import { useAuthStore } from "@/stores/authStore.tsx";
import { captureException } from "@sentry/react";

function FilloutMriFormPage() {
	const brokerApiKey = useAuthStore((state) => state.brokerApiKey);
	if (!brokerApiKey) {
		const err = new Error("brokerApiKey is undefined");
		captureException(err, {
			data: {
				brokerApiKey,
			},
		});

		throw err;
	}
	return (
		<FilloutFullScreenEmbed
			parameters={{ partnershipId: brokerApiKey }}
			filloutId={import.meta.env.VITE_PUBLIC_FILLOUT_FORM_ID}
			inheritParameters
		/>
	);
}

export default FilloutMriFormPage;
